<script>
import { VclList } from 'vue-content-loading';
import {ServerState} from "@/state/helpers";

export default {
  components: {

  },
  props: {

  },
  methods: {
    handleError: function(error) {
      console.log(`[ERROR] ${error}`);
      this.error = true;
    },
    getData: function() {
      fetch(process.env.VUE_APP_ROOT_API + 'v1/servers/list', {credentials: 'include'})
        .then(response => {
          if(response.ok){
            return response.json();
          } else {
            throw new Error(`(${this.$vnode.componentOptions.tag}) Failed with API error ${response.status}=${response.statusText} (${response.url})`);
          }
        })
        .then(data => {
          this.ready = true;
          this.servers = data.servers;
        })
        .catch(error => {
          this.handleError(error);
        });
    }
  },
  created() {
    this.getData()
  },
  data() {
    return {
      ready: false,
      error: false,
      servers: [],
      ServerState: ServerState
    }
  }
};
</script>

<template>
  <div class="col-xl-12 col-sm-12" v-if="ready && servers.length">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title mb-4">{{ $t('dashboard.servers.title') }}</h4>
          <div class="table-responsive mb-0" v-if="servers.length">
            <table class="table table-centered table-nowrap">
              <thead class="thead-light">
              <tr>
                <th>{{ $t('dashboard.servers.columns.identifier') }}</th>
                <th>{{ $t('dashboard.servers.columns.name') }}</th>
                <th>{{ $t('dashboard.servers.columns.players') }}</th>
                <th>{{ $t('dashboard.servers.columns.rating') }}</th>
                <th></th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="data in servers" :key="data.id">
                <td>
                  <b class="text-body font-weight-bold">{{data.entity.identifier}}</b>
                </td>
                <td>{{data.gameserver.name}}</td>
                <td>
                  {{data.gameserver.players}} / {{data.gameserver.slots}}
                  <small v-if="data.gameserver.queue">
                    (+ {{data.gameserver.queue_size}})
                  </small>
                </td>
                <td>
                  <template v-if="data.gameserver.rank === -1 || !data.gameserver.rank">
                    <small class="text-uppercase">{{ $t('dashboard.servers.not_rated') }}</small>
                  </template>
                  <template v-else>
                    #<b>{{ data.gameserver.rank }}</b>
                  </template>
                </td>
                <td>
                  <router-link tag="button" :to="`/dashboard/${data.entity.id}`" class="mr-1 btn btn-outline-primary btn-sm">
                    Dashboard
                  </router-link>

                  <router-link tag="button" :to="`/server/${data.entity.id}`" class="ml-1 btn btn-outline-dark btn-sm">
                    Manage
                  </router-link>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
      </div>
    </div>
  </div>
  <div class="col-xl-12 col-sm-12" v-else-if="ready && !servers.length">
    <div class="text-center mt-4">
      <h2 class="text-center text-muted text-uppercase">
        {{ $t('dashboard.servers.no_servers.title') }}

        <div class="row mt-3">
          <div class="col">
            <router-link to="/servers/add" style="pointer-events: auto !important;">
              <button class="btn btn-new-pulse btn-primary btn-lg text-uppercase">
                <i class="fad fa-plus"/>
                Add server
              </button>
            </router-link>
          </div>
        </div>
      </h2>
    </div>
  </div>
</template>
